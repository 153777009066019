.fs-22 {
  font-size: 1.375rem;
}
.fs-15 {
  font-size: 0.938rem;
}
.form-control-paragraphs {
  padding-top: 0.5rem;
  font-weight: 300;
  color: #4a4a4a;
  line-height: 1.313rem;
  text-align: justify;
}
.content-header__info {
  align-items: flex-start;
  display: flex;
  margin-top: 0.75rem;
  width: 100%;
}
.content-header__section {
  font-weight: 300;
  max-width: 12.5rem;
  padding-right: 2rem;
}
.content-header__wrapper {
  padding: 0rem 0rem 0 0rem;
  /* background-color: "$body-bg"; */
  z-index: 10;
}
.content-header__name,
.content-header__name {
  margin-bottom: 0rem;
  font-weight: 300;
}
.content-header__heading {
  font-weight: normal;
  font-size: 2rem;
  margin-bottom: 0.125rem;
}
.content-header__info {
  font-size: 0.75rem;
  font-weight: 300;
  color: "@emperor";
  margin-bottom: 1.125rem;
}
